import React from 'react';
import Helmet from '../project/Helmet';
import InventoryClassAdd from './inventoryClasses/InventoryClassAdd';
import InventoryClassList from './inventoryClasses/InventoryClassList';
import InventoryClassRefresh from './inventoryClasses/InventoryClassRefresh';
import { t } from '@lingui/macro';

/**
 * @fero
 */

class InventoryClassesPage extends React.PureComponent {
    render() {
        return <React.Fragment>
            <Helmet
                title={t`Skladové kategórie`}
            />
            <div className="full-size-height d-flex flex-column">
                <div className="pt-2 flex-item-static">
                    <InventoryClassAdd/>
                    <InventoryClassRefresh/>
                </div>
                <div className="m-3 overflow-auto flex-item-dynamic-1">
                    <InventoryClassList/>
                </div>
            </div>
        </React.Fragment>;
    }

}

export default InventoryClassesPage;