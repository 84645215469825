import React from 'react';
import generalFailedPC from '../../fetch/generalFailedPC';
import Tooltip from '../../general/Tooltip';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {createFetchInventoryRefreshClasses} from '../../../backend/apiCalls';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @dusan
 */

class InventoryClassRefresh extends React.PureComponent {
    render() {
        return <FormModal
            openNode={
                <Button className="add-max-width" icon="redo">
                    <span className="ml-2">
                        <Trans>Prepočítať skladové kategórie</Trans>
                    </span>
                </Button>
            }
            values={{}}
            title={<Trans>Prepočet skladových kategorií</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Táto operácia môže dlho trvať. Naozaj chcete pokračovať?</Trans>,
                createFetchInventoryRefreshClasses(),
            )}
            Response={null}
            Failed={generalFailedPC(t`Pri prepočte sa vyskytla chyba.`)}
        />;
    }
}

export default InventoryClassRefresh;