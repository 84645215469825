import React from 'react';
import List from '../../general/List';
import withDataHOC from '../../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {InventoryClasses} from '../../../constants/propTypesDefinitions';
import InventoryClassEdit from './InventoryClassEdit';
import InventoryClassRemove from './InventoryClassRemove';
import {Trans, t} from '@lingui/macro';

/**
 * @dusan
 */

class InventoryClassList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: null,
        }
    }

    static propTypes = {
        [GLOBAL_DATA.INVENTORY_CLASSES]: InventoryClasses.isRequired,
    };

    onSelect = (classId) => {
        this.setState({
            selectedId: classId,
        })
    };

    render() {
        const {selectedId} = this.state;
        const inventoryClasses = this.props[GLOBAL_DATA.INVENTORY_CLASSES];
        return <List
                values={inventoryClasses}
                onItemClick={(value) => {
                    this.onSelect(value.id);
                }}
                selectedValueId={selectedId}
            >
                {inventoryClasses.map((obj = {}, index) => {
                    return <div key={index} className="d-flex flex-wrap align-items-center">
                        <span className="font-weight-bold">{obj.sequence + '. ' + obj.name}</span>
                        { obj.sales_count_min != null || obj.sales_count_max != null ?
                            <span className="mx-2">
                                {'|'}
                                {obj.sales_count_min != null ? 
                                    <span className="ml-2">
                                        <Trans>od</Trans>
                                        {' '}
                                        {obj.sales_count_min}
                                    </span> : null
                                }
                                {obj.sales_count_max != null ? 
                                    <span className="ml-2">
                                        <Trans>do</Trans>
                                        {' '}
                                        {obj.sales_count_max}
                                    </span> : null
                                }
                                <span className="ml-2">
                                    <Trans>predajov za 2 roky</Trans>
                                </span>
                                    
                            </span> : null
                        }
                        { obj.customers_count_min != null || obj.customers_count_max != null ?
                            <span className="mx-2">
                                {'|'}
                                {obj.customers_count_min != null ? 
                                    <span className="ml-2">
                                        <Trans>od</Trans>
                                        {' '}
                                        {obj.customers_count_min}
                                    </span> : null
                                }
                                {obj.customers_count_max != null ? 
                                    <span className="ml-2">
                                        <Trans>do</Trans>
                                        {' '}
                                        {obj.customers_count_max}
                                    </span> : null
                                }
                                <span className="ml-2">
                                    <Trans>rôznych zákazníkov za 2 roky</Trans>
                                </span>
                            </span> : null
                        }
                        { obj.sales_age_min != null || obj.sales_age_max != null ?
                            <span className="mx-2">
                                {'|'}
                                {obj.sales_age_min != null ? 
                                    <span className="ml-2">
                                        <Trans>od</Trans>
                                        {' '}
                                        {obj.sales_age_min}
                                    </span> : null
                                }
                                {obj.sales_age_max != null ? 
                                    <span className="ml-2">
                                        <Trans>do</Trans>
                                        {' '}
                                        {obj.sales_age_max}
                                    </span> : null
                                }
                                <span className="ml-2">
                                    <Trans>dní od posledného predaja</Trans>
                                </span>
                            </span> : null
                        }
                        { obj.stock_age_min != null || obj.stock_age_max != null ?
                            <span className="mx-2">
                                {'|'}
                                {obj.stock_age_min != null ? 
                                    <span className="ml-2">
                                        <Trans>od</Trans>
                                        {' '}
                                        {obj.stock_age_min}
                                    </span> : null
                                }
                                {obj.stock_age_max != null ? 
                                    <span className="ml-2">
                                        <Trans>do</Trans>
                                        {' '}
                                        {obj.stock_age_max}
                                    </span> : null
                                }
                                <span className="ml-2">
                                    <Trans>dní na sklade</Trans>
                                </span>
                            </span> : null
                        }
                        {obj.id == selectedId ? [
                            <InventoryClassEdit
                                key="edit"
                                inventoryClass={obj}
                            />,
                            < InventoryClassRemove
                                key="remove"
                                inventoryClass={obj}
                            />
                        ] : null}
                    </div>
                })}
        </List>;
    }

}

export default withDataHOC([GLOBAL_DATA.INVENTORY_CLASSES])(InventoryClassList);